import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

const Content = () => {
  const { t, lang } = useLocale()

  return (
    <section className="financing-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7">
            <h3 className="financing-content__description">
              {t(
                "Pomożemy w uzyskaniu kredytu hipotecznego i wspólnie przejdziemy przez wszystkie formalności."
              )}
            </h3>
          </div>
        </div>

        <div className="row">
          {lang === "pl" && (
            <div className="col-md-7">
              <div
                id="notus-rata-wynik_api-js"
                data-wersja="zakresy"
                data-firma="Moja Klonowa"
                data-css="https://trust-investment.zenx.pl/calc-styles.css"
              ></div>
            </div>
          )}
          <div className="col-md-4 offset-md-1">
            <div className="financing-content__person">
              <h4>{t("Ekspert Kredytowy")}</h4>
              <img
                className="financing-content__person-image"
                src={
                  require("assets/images/financing-content-image.jpg").default
                }
                alt=""
              />
              <p>
                <strong>Agata Niechciał</strong>
              </p>
              <a href="tel:+48 885 220 930">+48 885 220 930</a>
              <a href="mailto:agata.niechcial@notus.pl">
                agata.niechcial@notus.pl
              </a>
              <img
                src={
                  require("assets/images/financing-content-notus-logo.png")
                    .default
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
